import { defineStore } from 'pinia'
import tradingQuery from '../apollo/queries/pages/trading.gql'

export const useTradingStore = defineStore('trading', {
  state: () => ({
    data: {},
    timeToRefresh: 60000
  }),
  getters: {
    getData: (state) => {
      return state.data.tradingMember ? state.data.tradingMember.data.attributes : null
    },
    getTimeToRefresh: (state) => {
      return state.timeToRefresh
    }
  },
  actions: {
    async requestData() {
      if (Object.keys(this.data).length !== 0) return

      const { data } = await useAsyncQuery(tradingQuery) // eslint-disable-line no-undef

      if (data) this.data = data
    }
  }
})
